import { PageActionsEvent, PageSettingsTab } from './types/manifest';
import { Translations } from './Translations';
import {
  PAYMENT_PAGE_MANIFEST_KEY,
  THANK_YOU_PAGE_MANIFEST_KEY,
} from './app-config';

// https://bo.wix.com/wix-docs/client/client-frameworks#editor-platform.articles.pages-manifest
export function getChallengesManifest(translation: Translations) {
  return {
    pages: {
      pageActions: {
        default: [
          {
            type: 'Pages_Actions_Page_Rename',
          },
          {
            title: translation.t('challenge.page.delete'),
            icon: 'delete_icon',
            event: PageActionsEvent.REMOVE,
            type: 'page_remove',
          },
        ],
        [PAYMENT_PAGE_MANIFEST_KEY]: [],
        [THANK_YOU_PAGE_MANIFEST_KEY]: [],
      },
      pageSettings: {
        default: [
          // {
          //   title: translation.t('challenge.page.info'),
          //   type: PageSettingsTab.PAGE_INFO,
          // },
          {
            title: translation.t('challenge.page.layout'),
            type: PageSettingsTab.LAYOUT,
          },
          {
            title: translation.t('challenge.page.permissions'),
            type: PageSettingsTab.PERMISSIONS,
          },
          // {
          //   title: translation.t('challenge.page.seo'),
          //   type: PageSettingsTab.SEO,
          // },
        ],
        [PAYMENT_PAGE_MANIFEST_KEY]: [],
        [THANK_YOU_PAGE_MANIFEST_KEY]: [],
      },
      applicationSettings: {
        default: {
          displayName: 'Challenges Pages',
          helpId: '',
        },
      },
      applicationActions: {
        default: [],
      },
    },
  };
}
