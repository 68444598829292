export const CHALLENGE_PAGE_ID = 'challenge_page';
export const PAYMENT_PAGE_ID = 'Challenge Payment';
export const THANK_YOU_PAGE_ID = 'Thank You Page';
export const PAYMENT_PAGE_MANIFEST_KEY = 'paymentPage';
export const THANK_YOU_PAGE_MANIFEST_KEY = 'challengeThankYou';
export const CHALLENGE_PAYMENT_PAGE_TYPE =
  '0daa3702-0e20-48ca-ba15-ad380e00e6b6';
export const CHALLENGE_THANK_YOU_PAGE_TYPE =
  'a159b4d2-831b-410c-9e77-ec3f40c9cace';
export const CHALLENGES_PAGES = [
  CHALLENGE_PAGE_ID,
  PAYMENT_PAGE_ID,
  THANK_YOU_PAGE_ID,
];
export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

export const PAGE_TITLES = {
  [CHALLENGE_PAGE_ID]: 'Challenge Page',
  [PAYMENT_PAGE_ID]: 'Payment Page',
  [THANK_YOU_PAGE_ID]: 'Thank You Page',
};

export const CHALLENGES_PLATFORM_CONTROLLER_TYPE = 'challengesPlatform';

export const NOTIFICATIONS_APP_ID = '14f25924-5664-31b2-9568-f9c5ed98c9b1';
export const NOTIFICATIONS_PAGE_ID = 'notifications_app';
export const NOTIFICATIONS_PAGE = {
  numbers: {
    key: 'notificationsCount',
    default: 0,
  },
  appDefinitionId: NOTIFICATIONS_APP_ID,
  pageId: NOTIFICATIONS_PAGE_ID,
  method: 'addApplication',
  menuOrder: 4,
  social: false,
  urlOverride: 'notifications',
};

export const MEMBERSHIP_APP_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
