import { ChallengesPlatform } from './editor/ChallengesPlatform';
import { PageActionsEvent } from './editor/types/manifest';
import {
  CHALLENGE_PAGE_ID,
  MEMBERS_APP_DEF_ID,
  PAYMENT_PAGE_ID,
  THANK_YOU_PAGE_ID,
} from './editor/app-config';
import { getChallengesExperiments, isEnabled } from './services/experiments';
import { Challenges } from './editor/types/Experiments';
import { EditorReadyFn } from 'yoshi-flow-editor-runtime';

let platform: ChallengesPlatform;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  { firstInstall },
) => {
  const experiments = await getChallengesExperiments();

  platform = new ChallengesPlatform(editorSDK, appDefinitionId, experiments);

  const isChallengesInstalled = await platform.isChallengesInstalled();

  if (!isChallengesInstalled) {
    return;
  }

  await platform.initTranslations();

  const showInEditorPageMenu = [CHALLENGE_PAGE_ID];
  if (isEnabled(experiments[Challenges.paymentPage])) {
    showInEditorPageMenu.push(PAYMENT_PAGE_ID);
    showInEditorPageMenu.push(THANK_YOU_PAGE_ID);

    // install dependant apps in background when experiment is ON.
    !firstInstall && (await platform.installDependencies(false));
  }

  await platform.setupPages({
    isFirstInstall: firstInstall,
    showInEditorPageMenu,
  });

  if (firstInstall) {
    if (isEnabled(experiments[Challenges.paymentPage])) {
      await platform.openInstallationPopup();
    }
  } else {
    // https://github.com/wix-private/catalyst-server/blob/master/file-sharing-server/file-sharing-statics/src/files/editor-script/file-sharing-editor-script-stg.js#L241
    try {
      await platform.hackForTemplates();
    } catch (e) {
      console.error('[Challenges Platform]: Hack for templates failed');
    }
  }
};

export const getAppManifest = async () => {
  return platform.getManifest();
};

export const onEvent = async event => {
  switch (event.eventType) {
    case PageActionsEvent.REMOVE:
      console.log('DELETE APP');
      return platform.deleteApp(event.eventPayload);
    default:
      return;
  }
};

export const handleAction = async event => {
  const { type, payload } = event;
  const experiments = await getChallengesExperiments();
  const isPaymentPageExperimentEnabled = isEnabled(
    experiments[Challenges.paymentPage],
  );

  console.log('>: handleAction call', event);
  if (type === 'appInstalled') {
    console.log(event);
    try {
      switch (payload.appDefinitionId) {
        case platform.appDefId:
          if (isPaymentPageExperimentEnabled) {
            await platform.installDependencies();
          }
          break;
        case MEMBERS_APP_DEF_ID:
          if (isPaymentPageExperimentEnabled) {
            await platform.installMembersApps();
          }
          break;

        default:
      }
    } catch (e) {
      console.error('[Challenges]: error handle action:', e);
      throw e;
    }
  }
};
