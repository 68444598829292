import EditorWrapper from './EditorWrapper';
import {
  CHALLENGES_PAGES,
  CHALLENGE_PAGE_ID,
  MEMBERS_APP_DEF_ID,
  NOTIFICATIONS_PAGE,
  NOTIFICATIONS_APP_ID,
  MEMBERSHIP_APP_ID,
  PAYMENT_PAGE_MANIFEST_KEY,
  PAYMENT_PAGE_ID,
  THANK_YOU_PAGE_MANIFEST_KEY,
  THANK_YOU_PAGE_ID,
} from './app-config';
import { ChallengesAppSpec } from './types/AppSpec';
import { Translations } from './Translations';
import { getChallengesManifest } from './manifest';
import AppManifest from './types/manifest';
import { ISetupPagesOptions, PageData, TPARef } from './types/common';
import { id as widgetId } from '../components/ChallengesList/.component.json';
import { MembersApi } from './types/MembersApi';
import { TranslationFunction } from 'react-i18next';
import { IChallengeExperiments, Challenges } from './types/Experiments';
import { isEnabled } from '../services/experiments';

export class ChallengesPlatform {
  private readonly editor: EditorWrapper;
  private readonly translations = new Translations();
  private t: TranslationFunction = null;

  constructor(
    editorSDK,
    public readonly appDefId: string,
    public readonly experiments: IChallengeExperiments,
  ) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
  }

  async isChallengesInstalled() {
    return this.editor.isAppInstalled(this.appDefId);
  }

  async initTranslations() {
    const {
      appFields: {
        platform: { baseUrls },
      },
    } = await this.editor.getAppData<ChallengesAppSpec>();
    const baseUrl = baseUrls.staticsEditorBaseUrl;
    const locale = await this.editor.getLocale();

    await this.translations.editorInit(locale, baseUrl);

    this.t = this.translations.t.bind(this.translations);
  }

  async openInstallationPopup() {
    await this.editor.openInstallationProgress(this.t);
  }

  async installDependencies(shouldUpdatePopup = true) {
    if (shouldUpdatePopup) {
      await this.editor.updateInstallationProgress(this.t, 2);
    }

    const isMAInstalled = await this.editor.isAppInstalled(
      NOTIFICATIONS_APP_ID,
    );
    const isMembershipInstalled = await this.editor.isAppInstalled(
      MEMBERSHIP_APP_ID,
    );
    const isPaidPlansExperimentEnabled = isEnabled(
      this.experiments[Challenges.arePaidPlansEnabled],
    );

    console.groupCollapsed('Members install');
    !isMAInstalled &&
      (await this.editor.installApplication(MEMBERS_APP_DEF_ID));
    isPaidPlansExperimentEnabled &&
      !isMembershipInstalled &&
      (await this.editor.installTPA(MEMBERSHIP_APP_ID));
    console.groupEnd();

    if (shouldUpdatePopup) {
      await this.editor.updateInstallationProgress(this.t, 3);
      await this.editor.closeInstallationProgress();
    }
  }

  async installMembersApps() {
    const membersApi = await this.getMembersApi();

    await this.installMembersSections(membersApi);
    await this.setMembersDependantApps(membersApi);
  }

  async installMembersSections(membersAPI: MembersApi) {
    // TODO: return GROUP_MEMBERS_AREA_PAGE installation later
    //  see https://jira.wixpress.com/browse/GROUP-673
    await membersAPI.addApplications([NOTIFICATIONS_PAGE]);
  }

  async setMembersDependantApps(membersAPI: MembersApi) {
    await membersAPI.setDependantApps({
      appId: this.appDefId,
      dependencies: [NOTIFICATIONS_APP_ID, this.appDefId],
    });
  }

  private async getMembersApi() {
    return (await this.editor.getAPI(MEMBERS_APP_DEF_ID)) as MembersApi;
  }

  async setupPages(options: ISetupPagesOptions) {
    const { showInEditorPageMenu = [], isFirstInstall } = options;
    const addedPages = await this.installPages();

    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);

    const { applicationId } = tpaData;
    const challengesPages = this.getChallengesTPARefs(
      allSitePages,
      applicationId,
    );

    const thankYouPage = challengesPages.find(
      (page) => page.tpaPageId === THANK_YOU_PAGE_ID,
    );

    try {
      await this.editor.updatePage(thankYouPage.pageRef, {
        pageUriSEO: 'challenge-thanks',
      });

      await this.editor.save();
      console.log('[challenges]: Thank you page migrated');
    } catch (e) {
      console.error('[challenges]:error in migrating thank you page');
    }

    if (isFirstInstall) {
      const allComponents = await this.editor.getAllComponents();
      const challengesWidget = await this.getChallengesWidgetRefs(
        allComponents,
        this.appDefId,
      );
      await this.editor.highlightComponent(challengesWidget[0]);

      // remove highlight on component
      setTimeout(() => {
        this.editor.removeHighlightComponent();
      }, 1000);

      await this.editor.setPageInitialTitles(challengesPages);
    } else if (addedPages.length) {
      await this.editor.setPageInitialTitles(
        challengesPages.filter((page) => addedPages.includes(page.tpaPageId)),
      );
    }

    const isPageUpdated = await this.editor.addPagesToEditorPageMenu(
      challengesPages,
      showInEditorPageMenu,
    );

    if (isPageUpdated || addedPages.length || isFirstInstall) {
      await this.editor.save();
    }

    await this.linkPageWithManifest({
      manifestKey: PAYMENT_PAGE_MANIFEST_KEY,
      tpaPageId: PAYMENT_PAGE_ID,
      applicationId: this.appDefId,
    });

    await this.linkPageWithManifest({
      manifestKey: THANK_YOU_PAGE_MANIFEST_KEY,
      tpaPageId: THANK_YOU_PAGE_ID,
      applicationId: this.appDefId,
    });
  }

  private async installPages(): Promise<string[]> {
    const allSitePages = await this.editor.getAllPages();
    const addedPages = [];
    await Promise.all(
      CHALLENGES_PAGES.map(async (tpaPageId) => {
        const isPageInstalled = allSitePages.some(
          (page) => page.tpaPageId === tpaPageId,
        );

        if (!isPageInstalled) {
          await this.editor.addPage(tpaPageId);
          addedPages.push(tpaPageId);
        }
      }),
    );

    return addedPages;
  }

  async hackForTemplates() {
    const tpaData = await this.editor.getDataByAppDefId(this.appDefId);
    const instance = JSON.parse(atob(tpaData.instance.split('.')[1]));
    const hasOriginInstanceId = !!instance.originInstanceId;

    if (!instance.biToken && hasOriginInstanceId) {
      await this.editor.save();
    }
  }

  private getChallengesTPARefs(allSitePages, applicationId): TPARef[] {
    return allSitePages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          CHALLENGES_PAGES.includes(page.tpaPageId),
      )
      .map(({ id, tpaPageId, title, managingAppDefId }) => {
        return {
          title,
          tpaPageId,
          managingAppDefId,
          pageRef: { id },
        };
      });
  }

  async getManifest(): Promise<AppManifest> {
    return getChallengesManifest(this.translations);
  }

  private getChallengesTPARef(allSitePages, applicationId) {
    const challengesPage: PageData = allSitePages.find(
      (page) =>
        page.tpaApplicationId === applicationId &&
        page.tpaPageId === CHALLENGE_PAGE_ID,
    );
    return { title: CHALLENGE_PAGE_ID, pageRef: { id: challengesPage.id } };
  }

  private async getChallengesWidgetRefs(allComponents, applicationId) {
    const challengesWidgets: any = await Promise.all(
      allComponents.map(async (compRef) => {
        const data = await this.editor.getComponentData(compRef);

        if (
          data &&
          data.appDefinitionId === applicationId &&
          data.widgetId === widgetId
        ) {
          return compRef;
        }

        return null;
      }),
    );

    return challengesWidgets.filter(Boolean);
  }

  async linkPageWithManifest(options: {
    applicationId: string;
    manifestKey: string;
    tpaPageId: string;
  }) {
    const { applicationId, manifestKey, tpaPageId } = options;
    try {
      const allSitePages = await this.editor.getAllPages();
      const groupPage: PageData = allSitePages.find((page) => {
        return (
          page.managingAppDefId === applicationId &&
          page.tpaPageId === tpaPageId
        );
      });
      const { id } = groupPage;
      await this.editor.setPageState({ [manifestKey]: [{ id }] });
    } catch (e) {
      console.error('Set group page state: FAIL');
    }
  }

  async deleteApp(eventPayload: {
    origin: string;
    publicUrl: string;
    pageRef: { id: string; type: string };
  }) {
    try {
      await this.editor.deletePage({
        pageRef: { id: eventPayload.pageRef.id },
      });
      await this.editor.deleteApp();
    } catch (e) {
      console.warn('[challenges]', e);
    }
  }

  handleInstallError() {}
}
