export enum Challenges {
  enableLocalization = 'specs.challenges.OOIEnableLocalization',
  enableMembersInvitation = 'specs.challenges.EnableMembersInvitation',
  stepCardShortCurrentDayTitle = 'specs.challenges.StepCardShortCurrentDayTitle',
  challengesNewTemplatesModal = 'specs.challenges.ChallengesNewTemplatesModal',
  arePaidPlansEnabled = 'specs.challenges.ArePaidPlansEnabled',
  paymentPage = 'specs.challenges.WebEnableMemberFlow',
  showBadges = 'specs.challenges.WebMemberShowBadges',
  renderSeoTags = 'specs.challenges.WebEnableRenderSeoTags',
}

export type ExperimentBoolean = 'true' | 'false';

export interface IChallengeExperiments {
  [index: string]: ExperimentBoolean;
  [Challenges.arePaidPlansEnabled]?: ExperimentBoolean;
  [Challenges.enableLocalization]?: ExperimentBoolean;
  [Challenges.enableMembersInvitation]?: ExperimentBoolean;
  [Challenges.stepCardShortCurrentDayTitle]?: ExperimentBoolean;
  [Challenges.challengesNewTemplatesModal]?: ExperimentBoolean;
  [Challenges.arePaidPlansEnabled]?: ExperimentBoolean;
  [Challenges.paymentPage]?: ExperimentBoolean;
  [Challenges.showBadges]?: ExperimentBoolean;
  [Challenges.renderSeoTags]?: ExperimentBoolean;
}
